.documentList, .documentShowcase{
  text-align: center;
  background-color: white;
  padding: 10px;
 /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
 border: 1px solid lightgray;
 padding-bottom: 60px;
}
.documentShowcase img{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.documentList .documentTitle{
  width: 80%;
  margin: 0 auto;
  font-size: 12px;
  word-break: break-word;
}
.labelsList{
  border: 1px solid lightgray;
}
.documentSelected{
  background-color: #1976d22e !important;
}
.bm{
  width: 5px;
  margin-left: -10px;
  margin-right: 10px;
}
.docImage{
  width: 70%; 
  padding: 8px; 
  cursor: pointer;
  height: 190px;
}


.number{
  margin-left: 10px;
  font-weight: bold;
}
.documentList > div{
  margin-bottom: 10px;
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  padding: 10px;
}
.table{
  width: 100%;
  margin-top: 20px;
}

.table thead th{
  text-align: center;
}


.table tbody td{
  text-align: center;
}

.table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.table thead tr {
  background-color: #1976d2;
  color: #ffffff;
  text-align: left;
}
.table th,
.table td {
    padding: 12px 15px;
}
.table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table tbody tr:last-of-type {
  border-bottom: 2px solid #1976d2;
}
.errorMessage{
  margin-top: 10px;
  padding: 10px;
  color: red;
}
.pager{
  font-size: 14px;
  color:#2e7d32;
}
.arrowLeft{
  position: absolute;
   margin-top: -30px; 
   margin-left: -110px;
}
.false{
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;

  margin-left: 20px;
}

.stickyBar{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 10px;
  border-top: 1px solid lightgray;
  z-index: 100;
}
.infoDoc{
  float: left;
}