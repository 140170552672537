.closeFolder {
  height: 25px;
  overflow: hidden;
}

.modal-footer{
  height: 68px !important;
  margin-right: 15px;
}

.activeTree{
  padding: 5px 8px 9px 9px;
  background-color: #eef5fb;
}
.docFound{
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;

}
.openFolder {
  height: 100%;
  overflow: auto;
}

.imgFolderTree {
  width: 20px;
  height: 18px;
  margin: 0px 12px 0px 0px;
  color: #607d8b !important;
}

.imgArrowTree {
  font-size: 16px !important;
  margin: 7px 12px 0px 0px;
  color: #607d8b !important;
}

.MuiBackdrop-root{
  background-color: rgb(33 150 243 / 52%) !important;
}

.baseSearch {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  height:58px;
  img {
    width: 18px;
    height: 18px;
    margin: 19px 16px 0 16px;
    position: absolute;
    left: 0;
  }

  input {
    padding-left: 51px;
    font-style: italic;
    font-size: 16px;
    padding-top: 5px;
    height: 59px;
    width: 100%;
    border:0px;
  }

  ::-webkit-input-placeholder { /* Edge */
    color: #006ac6;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #006ac6;
  }

  ::placeholder {
    color: #006ac6;
  }
}

.baseContent {
  margin-top: -14px;

  .navS {
    padding: 0;
    margin: 0;
    min-width: 30px;
    max-width: 36px;
    margin-right: 16px;
    background-color: #ecf0f5;
  }
}

.iconTree {
  width: 22px;
  margin: 6px 0px 0px 7px;
  object-fit: cover;
  height: 22px;
}

.arrowContentBackPage {
  width: 36px;
  height: 36px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-right: 12px;
  margin-top: 2px;
  display: inline-block;
  float: left;
}

.arrowBackPage {
  width: 14px;
  margin: 11px 0px 0px 12px;
  object-fit: cover;
  height: 14px;
}


.spinnerDiv {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: -208px;
  right: 0;
  bottom: 0;
  margin: auto;
}

