.documentList, .documentShowcase{
  text-align: center;
  background-color: white;
  padding: 10px;
 /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
 border: 1px solid lightgray;
 padding-bottom: 60px;
}

.documentShowcase img{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.documentList .documentTitle{
  width: 80%;
  margin: 0 auto;
  font-size: 12px;
  word-break: break-word;
}

.labelsList{
  border: 1px solid lightgray;
}
.signed{
  background-color: white !important;
  color: black;
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px dotted #1976d2 !important;
}
.documentSelected{
  background-color: #1976d22e !important;
}

.bm{
  width: 5px;
  margin-left: -10px;
  margin-right: 10px;
}

.docImage{
  width: 70%; 
  padding: 8px; 
  cursor: pointer; 
}


.number{
  margin-left: 10px;
  font-weight: bold;
}

.documentList > div{
  margin-bottom: 10px;
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  padding: 10px;
}

.table{
  width: 100%;
  margin-top: 20px;
}

.table thead th{
  text-align: center;
}


.table tbody td{
  text-align: center;
}

.table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.table thead tr {
  background-color: #1976d2;
  color: #ffffff;
  text-align: left;
}

.table th,
.table td {
    padding: 12px 15px;
}

.table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table tbody tr:last-of-type {
  border-bottom: 2px solid #1976d2;
}

.errorMessage{
  margin-top: 10px;
  padding: 10px;
  color: red;
}

.btn-doc-eS {
  display: inline-flex;
  font-weight: 800;
  color: #666666;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1.0rem;
  justify-content: center;
  align-items: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 36px;
  height: 34px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ececec;
}

.btn-doc-primary .active {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-doc-primary-new:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

/*.btn-doc-primary-new.focus, .btn-doc-primary-new:focus {*/
/*  color: #fff;*/
/*  background-color: #006ac6;*/
/*  border-color: #006ac6;*/
/*  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)*/
/*}*/

.btn-doc-primary-new.disabled, .btn-doc-primary-new:disabled {
  color: #fff;
  background-color: #ececec;
  border-color: #ececec
}

.btn-doc-primary-new:not(:disabled):not(.disabled).active, .btn-doc-primary-new:not(:disabled):not(.disabled):active, .show > .btn-doc-primary-new.dropdown-toggle {
  color: #fff;
  background-color: #006ac6;
  border-color: #006ac6
}

.btn-doc-primary-new:not(:disabled):not(.disabled).active:focus, .btn-doc-primary-new:not(:disabled):not(.disabled):active:focus, .show > .btn-doc-primary-new.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}
