.documentList, .documentShowcase{
  text-align: center;
  background-color: white;
  padding: 10px;
 /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
 border: 1px solid lightgray;
 height: auto;
 padding-bottom: 60px;
}
.documentShowcase img{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.selectED i, .selectED br{
  display: none;
}
.documentList .documentTitle{
  width: 80%;
  margin: 0 auto;
  font-size: 12px;
  word-break: break-word;
}
.labelsList{
  border: 1px solid lightgray;
}
.documentSelected{
  background-color: #1976d22e !important;
}
.bm{
  width: 5px;
  margin-left: -10px;
  margin-right: 10px;
}
.docImage{
  width: 70%; 
  padding: 8px; 
  cursor: pointer; 
}


.number{
  margin-left: 10px;
  font-weight: bold;
}
.documentList > div{
  margin-bottom: 10px;
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  padding: 10px;
}
.table{
  width: 100%;
  margin-top: 20px;
}

.table thead th{
  text-align: center;
}


.table tbody td{
  text-align: center;
}

.table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.table thead tr {
  background-color: #1976d2;
  color: #ffffff;
  text-align: left;
}
.table th,
.table td {
    padding: 12px 15px;
}
.table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table tbody tr:last-of-type {
  border-bottom: 2px solid #1976d2;
}
.errorMessage{
  margin-top: 10px;
  padding: 10px;
  color: red;
}
.swal2-container {
  z-index: 99999;
}

.departmentSelect{
  margin-top: 20px;
  display: block !important;
}
.departmentName{
  margin-right: 10px;
  width: 40%;
  float: left;
}
.departmentAdd{
  width: 20%;
  float: left;
}

.departmentTitle{
  display: block;
  background-color: transparent;
  font-weight: bold;
  width: 100%;
  
}
.departmentTitle button{
  float: right;
    right: 14px;
}
.cards{
  margin-top: 20px;
  width: 100%;
  display: block;
  max-height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
}
.card{
  float: left;
  width: 48%;
  margin: 4px;
  display: block;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  display: block !important;
  overflow-y: auto;
  padding-top: 0px !important;
  margin-top: 20px;
  height: 180px;
  margin-bottom: 20px;


}
.buttonActions{
  position: absolute;
  bottom: 42px;
  float: right;
  right: 60px;
}
.listItem{
  padding: 0px !important;
  margin-left: 0px !important;
}
.listItem span{
  font-size: 12px !important;
}
.listItem p{
  font-size: 12px !important;
  display: none;
}
.departmentContent{
  height: 290px;
  overflow-y: auto;

  width: 100%;
}
.departmentContent li{
  height: 30px !important;
}
.imageSVG{
  vertical-align: bottom;
  float: right;
}
.imageSVGDisabled{
  vertical-align: bottom;
  opacity: 0.5;
  color: lightGray;
  float: right;
}
@media (max-width: 768px) {
  .documentList, .documentShowcase {
    padding: 5px;
    padding-bottom: 40px;
  }
  .documentList .documentTitle {
    font-size: 10px;
  }
  .docImage {
    width: 100%;
  }
  .table {
    font-size: 0.8em;
  }
  .table th,
  .table td {
    padding: 8px 10px;
  }
  .departmentName {
    width: 50%;
  }
  .departmentAdd {
    width: 30%;
  }
  .cards {
    display: block;
  }
  .card {
    width: 100%;
    display: block;
  }
}

