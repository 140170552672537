.wrapperNew{
  width: 100%;
  height: 40px;
  display: inline-block;
  margin: 8px 0 0 0;
  button{
    background-color: #4e8b07;
    height: 40px;
  }
}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  opacity: 1;
  color: #fff !important;
}
.baseSearch {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  height:58px;
  img {
    width: 18px;
    height: 18px;
    margin: 19px 16px 0 16px;
    position: absolute;
    left: 0;
  }

  input {
    padding-left: 51px;
    font-style: italic;
    font-size: 16px;
    padding-top: 5px;
    height: 26px;
    margin-top: 13px;
  }



  ::-webkit-input-placeholder { /* Edge */
    color: #006ac6;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #006ac6;
  }

  ::placeholder {
    color: #006ac6;
  }
}

.spinnerDiv {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: -208px;
  right: 0;
  bottom: 0;
  margin: auto;
}

.MuiTableHead-root{
  background-color: #006ac6;
  color: #fff;
}

.MuiTableCell-head{
  color:#fff !important;
}
.MuiTableCell-row{
  background-color:#fff !important;
}
.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: lightgray !important;
}
.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  background-color: rgba(0, 0, 0, 0.04);
  color: white !important;
}
.MuiDataGrid-footerContainer{
 background-color: #fff;
}
.css-1xy1myn-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  right: -12px;
  display: none !important;
}

.space{
  margin-top: 20px;
}

.toolbarLabeling{
  border: 1px solid lightgray;
  
  display: flex;
  align-items: center;
 
}

