#card-thumbnail .row .col {
  padding-top: 1rem;
}

.pcoded-header.headerpos-fixed ~ .pcoded-main-container {
  //padding-top: 0px;
  //margin-top: 50px;
}

.MuiPopover-paper{
  margin-top: 7px !important;
  margin-left: 21px !important;
  border:2px solid #000 !important;
}
.restore{
  cursor: pointer;
  color: #000;
  text-decoration: underline;
  font-weight: bold;
}
.MuiListItemIcon-root{
  color: #fff !important;
}

.WithStyles:focus{
  background-color: transparent !important;
}

.spinnerDiv {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: -208px;
  right: 0;
  bottom: 0;
  margin: auto;
}

.Resizer {
  background: #eff3f7;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 25px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}


.tproperty thead th {

  font-size: 12px;
  /*border-bottom: 1px solid #e2e5e8;
  color: #37474f;
  background: #ecf0f5;
  text-transform: uppercase;*/
}


.tproperty td, .tproperty th {
  padding: 0.5rem 0.5rem;
}

.doc-danger, .doc-primary, .doc-success {
  padding: 0.5rem 0.5rem;
}

.doc-danger i {
  color: red;
  cursor: pointer;
}

.doc-primary i {
  color: #0d4982;
  cursor: pointer;
}

.doc-success i {
  color: #1EB549;
  cursor: pointer;
}


div[role="table"] {
  border: 1px solid #e2e5e8;
}

div[role="cell"] {
  border-left: 1px solid #dee2e6;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
*/


.type-danger i {
  color: red;
  font-size: 20px;
}

.type-primary i {
  color: #0d4982;
  font-size: 25px;
}

.type-mark i {
  color: #4680ff;
  font-size: 25px;
}

.type-warning i {
  color: #ffb300;
  font-size: 30px;
}

.type-success i {
  color: #1EB549;
  font-size: 25px;
}


.tab-primary i {
  color: #0d4982;
  font-size: 20px;
}


.toolbar {
  /*position: fixed;*/
  /*background-color: #222222;*/
  /*z-index: 8;*/
  height: 44px;
  top: 0;
  width: 100%;
  border-radius: 6px;
  display: flex;
  box-sizing: border-box;
  /*border: 1px solid blue;*/
  background-color: #ffffff;
}

.toolbar-section {
  z-index: 3;
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  /*padding: 8px 12px;*/
}

.toolbar {
  /*position: fixed;*/
  /*background-color: #222222;*/
  /*z-index: 8;*/
  height: 44px;
  top: 0;
  width: 100%;
  border-radius: 6px;
  display: flex;
  box-sizing: border-box;
  /*border: 1px solid blue;*/
  background-color: #ffffff;
}

.toolbar-section {
  z-index: 3;
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  /*padding: 8px 12px;*/
}

.toolbar-align-start {
  justify-content: flex-start;
  order: -1;
}

.toolbar-align-end {
  justify-content: flex-end;
  order: 1;
}


.fa-doc, .fab-doc, .fal-doc, .far-doc, .fas-doc {

  display: inline-flex;
  position: relative;
  /*flex: 1 1 auto;*/
  align-items: center;
  vertical-align: middle;
  /*top:50%;*/

  /*margin: 0;
  position: absolute;
  top: 50%;*/
  /*line-height: 1;*/
}


.btn-doc {
  display: inline-flex;
  font-weight: 800;
  color: #666666;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1.0rem;
  justify-content: center;
  align-items: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 36px;
  height: 31px;
  margin: -4px 4px 0 0px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ececec;
}

.btn-doc-new {
  display: inline-flex;
  font-weight: 800;
  color: #cccccc;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1.0rem;
  justify-content: center;
  align-items: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 36px;
  height: 31px;
  margin: 2px 0 0 0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #006ac6;
}

.btn-doc:hover {
  color: #fff;
}

/*.btn-doc.focus, .btn-doc:focus {
    border: none;
}*/
.btn-doc:hover {
  color: #212529;
  text-decoration: none
}

.btn-doc.focus, .btn-doc:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}


/*.btn-doc-primary {
    color: #fff;
    background-color: #006ac6;
    border-color: #006ac6;
}*/
.btn-doc-primary .active {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-doc-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-doc-primary.focus, .btn-doc-primary:focus {
  color: #fff;
  background-color: #006ac6;
  border-color: #006ac6;
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-doc-primary.disabled, .btn-doc-primary:disabled {
  color: #fff;
  background-color: #ececec;
  border-color: #ececec
}

.btn-doc-primary:not(:disabled):not(.disabled).active, .btn-doc-primary:not(:disabled):not(.disabled):active, .show > .btn-doc-primary.dropdown-toggle {
  color: #fff;
  background-color: #006ac6;
  border-color: #006ac6
}

.btn-doc-primary:not(:disabled):not(.disabled).active:focus, .btn-doc-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-doc-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}


/*
.btn:hover {
    color: #212529;
    text-decoration: none
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.btn.disabled, .btn:disabled {
    opacity: .65
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

a.btn.disabled, fieldset:disabled a.btn {
    pointer-events: none
}
*/


.doc-toolbar {
  /*border: 1px black solid;*/
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 6px;
  /*height: 44px;*/
  background-color: #ffffff;

  align-items: center;
}

.doc-btn-group {
  padding-left: 10px;
}

.doc-input-group {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  margin-right: 10px;
  background-color: #ececec;
  width: auto;
}

.doc-input-group > input {
  background-color: #ececec;
  border-bottom: none;
}

.doc-input-group > input:focus {
  background-color: #ececec;
  border-bottom: none;
}

.doc-input-group > input:active {
  background-color: #ececec;
  border-bottom: none;
}


.doc-btn-general {
  width: 100%;
  border-radius: 6px;
  font-size: 0.9rem;
}

.doc-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  /*border: 0px solid rgba(0, 0, 0, 0.125);*/
  /*border-radius: 0.25rem;*/

  border-radius: 6px;


  /*box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);*/
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out;

  /*padding: 20px 20px;*/
  overflow-x: hidden;
}

.doc-panel-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem
}


.doc-img-thumbnail {
  padding: 0.25rem;
  /*background-color: #ecf0f5;*/
  /*border: 1px solid #dee2e6;*/
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  position: relative;
  /*background-color: rgba(0,0,0,0.5);*/
}


.doc-img-overlay {
  /*padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  height: 100%;
  width: 80%;

  z-index: 2;*/
  /*padding: 0.25rem;*/
  z-index: 1;
  /* background: linear-gradient(
           180deg,
           rgba(34, 34, 34, 0.5) 0%,
           rgba(34, 34, 34, 0) 32.09%,
           rgba(34, 34, 34, 0.1) 50.36%,
           rgba(34, 34, 34, 0.5) 65.85%,
           #222 97.9%
   );*/
  border: 3px solid #006ac6;
  background-color: rgba(0, 106, 198, 0.65);
  height: 100%;
  width: 100%;
  opacity: 0.2;
  top: 0;
  left: 0;
  position: absolute;

  /*padding: 1rem;*/
  /*margin: 1rem;*/

  padding-right: 15px;
  padding-left: 15px;

  transition: opacity 0.5s;

}

.doc-img-background {
  background-color: #006ac6;
}


.doc-panel-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.0rem;
  font-size: 0.7rem;
}

.doc-panel-body > * {
  font-size: 0.7rem;
}

.doc-panel-body .form-control {
  font-size: 0.7rem;
}


.custom-item-body {
  width: 100%;
}

.custom-item-body .btn-group {
  width: 100%;
}

.custom-item-body input {
  width: 100%;
}

.color-picker {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.8), rgba(245, 245, 245, 0) 71%), conic-gradient(from 0.5turn, #6646e8, #56b144 0.24turn, #cbc544 0.5turn, #e8433d 0.75turn);
}

.color-picker:hover {
  cursor: pointer;
}


.doc-panel-color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.doc-panel-swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
}

.doc-panel-popover {
  position: absolute;
  z-index: 2;
}


.doc-panel-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}


.btn-doc-action {
  display: inline-flex;
  box-sizing: border-box;

  font-weight: 800;
  /*color: #373a3c;*/
  color: #666666;
  /*text-align: center;
  !*vertical-align: middle;*!*/

  -webkit-user-select: none;
  user-select: none;
  /*background-color: transparent;*/
  border: 1px solid transparent;
  /*padding: 0.625rem 1.1875rem;*/

  padding: 0.5rem 1.1875rem;
  /*padding-right: 0.5rem;
  padding-left: 0.5rem;*/

  /*font-size: 0.875rem;*/
  font-size: 1.0rem;


  justify-content: center;
  align-items: center;

  /*line-height: 1.5;*/

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  /*width: 36px;
  height: 24px;*/

  margin: 0 4px 0 0px;
  /*padding: 4px 10px;*/
  border-radius: 4px;
  background-color: #ececec;

}

/*.btn-doc-action.focus, .btn-doc-action:focus {
    border: none;
}*/
.btn-doc-action:hover {
  color: #212529;
  text-decoration: none
}

.btn-doc-action.focus, .btn-doc-action:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}


/*.btn-doc-action-primary {
    color: #fff;
    background-color: #006ac6;
    border-color: #006ac6;
}*/
.btn-doc-action-primary .active {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-doc-action-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-doc-action-primary.focus, .btn-doc-action-primary:focus {
  color: #fff;
  background-color: #006ac6;
  border-color: #006ac6;
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-doc-action-primary.disabled, .btn-doc-action-primary:disabled {
  color: #fff;
  background-color: #006ac6;
  border-color: #006ac6
}

.btn-doc-action-primary:not(:disabled):not(.disabled).active, .btn-doc-action-primary:not(:disabled):not(.disabled):active, .show > .btn-doc-action-primary.dropdown-toggle {
  color: #fff;
  background-color: #006ac6;
  border-color: #006ac6
}

.btn-doc-action-primary:not(:disabled):not(.disabled).active:focus, .btn-doc-action-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-doc-action-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.doc-fa {
  color: black;
  padding: 0;

  /*border-color:black;*/
}

.doc-fa:hover {
  /*color: white;
  border-color:white;*/
}

.doc-fa-triangle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid black;
}


.doc-fa-pentagon {
  position: relative;
  width: 10px;
  box-sizing: content-box;
  border-width: 10px 3px 0;
  border-style: solid;
  border-color: black transparent;
}

.doc-fa-pentagon:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  top: -15px;
  left: -4px;
  border-width: 0 9px 5px;
  border-style: solid;
  border-color: transparent transparent black;
}

.doc-space {
  padding-bottom: 5px;
}


.doc-fa-square {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background: black;
}

.doc-page-label {
  margin: 8px 37px 24px 40px;
  /*font-family: Roboto;*/
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #006ac6;
}


.doc-center {
  width: 100%;
  text-align: center;
}


.doc-row {
  /*position: fixed;*/
  /*background-color: #222222;*/
  /*z-index: 8;*/
  height: 44px;
  top: 0;
  width: 100%;
  border-radius: 6px;
  display: flex;
  box-sizing: border-box;
  /*border: 1px solid blue;*/
  background-color: #ffffff;
}

.buttonZoomW{
  margin: 10px 3px 10px 10px;
}

.doc-section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  /*padding: 8px 12px;*/
}

.doc-full-width {
  width: 100%
}

.doc-full-height {
  height: 100%
}


.doc-wrap-line {
  width: 17px;
  /*height: 50%;*/
  margin: 0 auto;
  position: relative;
}

.doc-line {
  height: 1px;
  background-color: black;
  padding: 1px;
}

.doc-left-rotate {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.doc-right-rotate {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}


.doc-arrow {
  display: inline-block;
  border: solid #FCA101;
  border-width: 0 3px 3px 0;
  padding: 3px;
}

.doc-arrow--up {
  transform: rotate(-135deg);
}

.doc-arrow--down {
  transform: rotate(45deg);
}

.doc-arrow--left {
  transform: rotate(135deg);
}

.doc-arrow--right {
  transform: rotate(-45deg);
}

.closeOptions{
  color: white;
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 14px;
  background-color: #006ac6;
  height: 25px;
  width: 50px;
  padding: 2px 0 0 4px;
  z-index: 9999;
  cursor: pointer;
}


.docViewer{
  margin: 16px auto;
  padding: 0;
  max-width: 100% !important;
  height: 92%;
  width: 100% !important;
  canvas{
    height: 50vh;
  }
  .canvas-container{
    margin: 0 auto;
  }
  .lower-canvas{
    width: 97% !important;
    height: 100% !important;
  }
  .upper-canvas{
    width: 100% !important;
    height: 100% !important;
  }

  .printDocument{
    width: 200px;
    height: 27px;
    font-size: 14px;
    background-color: green;
    color: #fff;
    border-radius: 5px;
    margin: 11px 0 0 46px;
    padding-top: 2px;
    cursor: pointer;
    position: relative;
  }

  .eSignature {
    width: 125px;
    height: 27px;
    font-size: 14px;
    background-color: #000000bd;
    color: #fff;
    border-radius: 5px;
    margin: 11px 0 0 46px;
    padding-top: 2px;
    cursor: pointer;
    position: relative;
  }

  .eSignatureAlone {
    float: right;
    width: 125px;
    height: 30px;
    font-size: 14px;
    background-color: #000000bd;
    color: #fff;
    border-radius: 5px;
    margin: 10px 0 0 46px;
    padding-top: 2px;
    cursor: pointer;
    position: relative;
  }

  .eSignatureText {
    position: absolute;
    right: 15px;
    top: 4px;
  }
  
  .optionDocument{
    width: 117px;
    height: 27px;
    font-size: 14px;
    background-color: #4680ff;
    color: #fff;
    border-radius: 5px;
    margin: 11px 0 0 46px;
    padding-top: 2px;
    cursor: pointer;
    position: relative;
  }

  .optionZoom{
    width: 117px;
    height: 31px;
    font-size: 14px;
    background-color: #ececec;
    color: #000;
    border-radius: 5px;
    margin: -4px 0 0 0px;
    padding-top: 2px;
    cursor: pointer;
    display: block;
    position: relative;
    .showZ{
      margin-top: 3px;
      display: inline-block;
      margin-left: 55px
    }
  }

  .optionZoom:hover{
    color: #ffffff;
  }

  .none{
    display: none;
  }

  .closeZoom{
    background-color: #f10606;
    color:#fff;
  }

  .optionZoom:hover{
    background-color: #4680ff;
  }

  .optionDocument:hover{
    background-color: #355fba;
  }

  .content{
    padding: 0px;
    text-align: center;
    height: 100%;
    display: inline-block;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 25px;
    .left{
      width: 20%;
      float: left;
      padding-right: 18px;
      .div{
        background-color: #ececec;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        padding: 12px;
        height: calc(100vh - 250px);
      }

    }
    .right{
      width: 80% !important;
      float: left;
      padding-right: 5px;
      overflow: auto;
      .div{
        background-color: #fff;
        max-height: 100%;
        padding: 0;
        height: calc(100vh - 250px);
        overflow-y: auto;
      }
    }
  }
  nav{
    width: 100%;
    background-color: #fff;
    height: 50px;
    margin-bottom: 20px;
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 50px;
      position: relative;
      .backBtn{
        margin: 4px 0 0 0 ;
        padding-left: 10px;
      }
      .itemGroup{
        padding: 13px 0px 0px 15px;
      }
      .sp{
        margin-left: 10px;
      }
      li{
        padding: 0;
        margin: 0;
        float: left;
        .save{
          position: absolute;
          margin: -55px 0px 0px 8px !important;
          color: #fff !important;
          font-weight: 100 !important;
          right: 3px;
          background-color: #5ba00c !important;
          padding: 5px 15px 5px 15px;
        }
        ul{
          margin-top: 7px;
          background-color: #b4b4b4;
          padding-left: 10px;
          padding-right: 10px;
          height: 34px;
          padding-top: 5px;
          li{
            margin-left: 7px;
          }
        }
        .delete{
          background-color: #ff5252;
          color: #fff;
        }
        .share{
          background-color: #006ac6;
          color:#fff
        }
        .share:hover{
          background-color: #5ba00c;
          color:#fff;
          border:0;
        }
      }
    }
    .btnPrintNavDocument {
    
    }
  }
}
