.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiListItem-padding{
  cursor: pointer !important;
}

.lineBreak {
  display: block;
}

.select__control {
  height: 56px !important;
}

.basic-multi-select{
  z-index: 1 !important;
}

.MuiInputBase-input{
  background-color: transparent !important;
}

.MuiDataGrid-cell{
  max-height: 100% !important;
  padding: 5px !important;
}

.MuiLinearProgress-root{
  height: 10px !important;
  border-radius: 5px !important;
}

.MuiDataGrid-row{
  max-height: 100% !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.filepicker{
  background-color: #fff !important;
  border: 2px dashed #006ac6 !important;
  height: 100% !important;
}

.MuiListItemIcon-root{
  min-width: 34px !important;
}

.react-confirm-alert-overlay{
    background: rgb(159 2 2 / 82%) !important;
    z-index: 1500 !important;
}

.deleteConfirm {
  width: 500px;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 45px;
}

.Toastify__toast-container{
  word-break: break-word;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-rte-demo {
  min-height: 110px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rdw-editor-main {
  background-color: #fff;
  padding: 5px;
  border: 1px solid #6e6c6c1f;
}

.public-DraftEditor-content {
  line-height: 0.5;
}

